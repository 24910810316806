<template>
  <v-footer
    color="primary"
    padless
  >
    <v-row
      justify="center"
      no-gutters
      style="margin:10px"
    >
      <v-col
        class="primary py-1 text-center"
        cols="24"
        md="24"
      >
        <v-btn
          v-for="(social, index) in socials"
          :key="index"
          rounded
          text
          :href="social.url"
          icon
        >
          <v-icon
            size="26px"
            color="black"
          >
            {{ social.icon }}
          </v-icon>{{ social.desc }}
        </v-btn>
      </v-col>
      <v-btn
        v-for="(link, index) in links"
        :key="index"
        text
        :href="link.url"
        class="my-2"
        small
      >
        <v-icon size="22px">
          {{ link.icon }}
        </v-icon>
        {{ link.name }}
      </v-btn>
    </v-row>
    <v-card-text
      class="text-center pa-0 primary lighten-1 m-6"
    >
      Copyright 2020 - {{ new Date().getFullYear() }}
      <a
        href=""
      >
        Achil-Jo</a>
    </v-card-text>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      links: [
        {
          name: '2106000014',
          icon: 'mdi-phone',
          url: 'tel:2106000014'
        },
        {
          name: '2106012458',
          icon: 'mdi-phone',
          url: 'tel:2106012458'
        },
        {
          name: '6982867069',
          icon: 'mdi-cellphone',
          url: 'tel:6982867069'
        },
        {
          name: '6977154272',
          icon: 'mdi-cellphone',
          url: 'tel:6977154272'
        },
        {
          name: 'Λεωφορος Μεσογειων 410 Αγ. Παρασκευη',
          icon: 'mdi-map-marker',
          url: '/contact-me'
        },
        {
          name: 'Δευτερα - Παρασκευη: 09:30 - 13:30, 17:00 - 20:30',
          icon: 'mdi-clock',
          url: '/contact-me'
        },
        {
          name: 'stavrosplytas.drivingschool@gmail.com',
          icon: 'mdi-email',
          url: 'https://mail.google.com/mail/?view=cm&fs=1&to=stavrosplytas.drivingschool@gmail.com'
        }
      ],
      socials: [
        {
          name: 'facebook',
          icon: 'mdi-facebook',
          url: 'https://www.facebook.com/%CE%A3%CF%87%CE%BF%CE%BB%CE%AE-%CE%BF%CE%B4%CE%B7%CE%B3%CF%8E%CE%BD-%CE%A3%CF%84%CE%B1%CF%8D%CF%81%CE%BF%CF%82-%CE%A0%CE%BB%CF%85%CF%84%CE%AC%CF%82-2410283335923844',
          desc: ''
        },
        {
          name: 'instagram',
          icon: 'mdi-instagram',
          url: 'https://www.instagram.com/plytas_drivingschool/',
          desc: ''
        }
      ]
    })
  }
</script>
